import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { NewsletterForm } from "../components/newsletter"
import { StaticImage } from "gatsby-plugin-image"
import NewsUpdates from "../components/NewsUpdates"

const ContentContainer = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  a {
    color: #FFF;
  }
  @media screen and (max-device-width: 800px) {
    padding: 0 1rem;
    text-align: center;
  }
`

const BlockquoteContainer = styled.blockquote`
    font-style: italic;
    border: 0.1rem solid #3e545e;
    border-radius: 1rem;
    padding: 2.5rem;
`

const IndexPage = ({ data }) => ( 
  <Layout>
    <Seo title="Home" />
    <ContentContainer>
      <NewsUpdates />
      <p>When everything seems hopeless a child is awakened by the whispers of the last leaf, drawn into an imaginary world.  </p>
      <p><span className="font-secondary">Unleaving</span> invites the player into a narrative-driven puzzle platformer, where each challenge unfolds within a hand-painted landscape, exploring metaphors and the elusive boundaries of perception and meaning.</p>
      <a
          href="https://youtu.be/ykVY1xtq0rg?si=bKY1q8cWxg-4lq-k"
          target="_blank"
          rel="noopener noreferrer"
        >
        <StaticImage
            className="image"
            src="../assets/images/assets-unleaving-awake-1-chapter.png"
            alt="Solitary reflection"
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
        />
      </a>
      <h2 className="font-secondary">Exploration </h2>
      <p>
        Unleaving takes its title from Gerard Manley Hopkins' poem:
      </p>
      <p>'Spring and Fall: To a Young Child.'</p>
      <p>
        It opens with the lines:
      </p>
      <p>
        'Márgarét, áre you gríeving
        Over Goldengrove unleaving?'
      </p>
      <p>
        In Hopkins' words, as the seasons change, 'Goldengrove' loses its leaves, is a metaphor for the transient nature of life. <span className="font-secondary">Unleaving</span> is a metaphorical game crafted through illustrations and puzzles context.
      </p>
      <BlockquoteContainer>"Unleaving isn't just a game, it's an immersive masterpiece that will leave you wanting more. From the hand painted artwork to the music, moments of story telling and inspirational quotes, it was just so beautiful." <a href="https://steamcommunity.com/id/rinkattv/recommended/1076720/">Rinka on Steam</a></BlockquoteContainer>
      <h2><span className="font-secondary">Art Experience</span></h2>     
      <p>Unleaving is as much a video game as it is an extended series of multimedia paintings and drawings.</p>
      <ul>
        <li>Every frame is a hand-painting, seamlessly integrating pencil and graphite puzzle elements.</li>
        <li>The visuals come to life through traditional painting animation.</li>
        <li>Embracing a commitment to originality, each scene is new, with no repetition.</li>
        <li>Each chapter is based on a specific theme that guides the mood, emotion, and story and, by extension, influences the colors and style.</li>
      </ul>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/wyGeF4UYw6k?si=ZFJla37gqGv0JkSH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <h2 className="font-secondary">Story Driven Puzzles</h2>
      <p>Through the gameplay featuring puzzles and skill-based challenges each tightly knit into the story's fabric, players are invited to immerse themselves in this emotional experience.</p> 
      <p>You can get <a className="font-secondary btn" href="https://store.steampowered.com/app/1076720?utm_source=source">Unleaving</a></p>
      <StaticImage
          className="image"
          src="../assets/images/assets_unleaving_video_game_puzzle_platformer_art_orangutan_matter_behind-the-scenes.jpeg"
          alt="behind the scenes - the making of Unleaving by sura Karnawi - hand painted art"
          layout="fullWidth"
          placeholder="blurred"
          loading="eager"
      />
      <BlockquoteContainer>"This work by the indie game studio 'Orangutan Matter' based in Toronto, Canada, expresses the depth of human emotions through art. The animations made by layering drawings and the brush-stroked, textured backgrounds are so beautiful you can't help but stop and gaze. 😳It was an artistic experience that made you feel like you were inside a painting." <a href="https://steamcommunity.com/profiles/76561199388982601/recommended/1076720/">~ Kaori Writer on Steam Reviews</a></BlockquoteContainer>
      <p>Image Caption: <i>Behind the scenes of making </i><span className="font-secondary">Unleaving</span></p>
      <StaticImage
          className="image"
          src="../assets/images/assets-unleaving-absurd-chapter.png"
          alt="reflecting on a stormy scene"
          layout="fullWidth"
          placeholder="blurred"
          loading="eager"
      />
      <p>Image caption:<span className="font-secondary"> Unleaving</span> <i>is an emotional puzzle platformer</i>.</p>
      <NewsletterForm />
    </ContentContainer>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    posterText: file(relativePath: { eq: "text-unleaving-title.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`